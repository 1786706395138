



























































import { Component, Vue } from 'vue-property-decorator'
import AuthService from '@/services/AuthService'

@Component({
  metaInfo: {
    meta: [
      {
        name: 'robots',
        content: 'noindex,nofollow,noarchive'
      }
    ]
  }
})
export default class ForgotPassword extends Vue {
  private email: string = ''
  private isLoading: boolean = false
  private sendMail: boolean = false
  private showErrorEmailMsg: boolean = false

  sendToEmail() {
    let original_url = window.location.origin + '/forgot-password'
    let site_url = window.location.origin + '/reset-password'
    const data = {
      email: this.email,
      site_url: site_url,
      original_url: original_url
    }
    AuthService.forgotPassword(data)
      .then(res => {
        if (res.status === 200) {
          this.sendMail = true
          this.showErrorEmailMsg = false
        }
      })
      .catch(error => {
        this.showErrorEmailMsg = true
      })
  }

  resetMsg() {
    this.sendMail = false
    this.showErrorEmailMsg = false
  }
}
